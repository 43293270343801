import React from "react"

export default function Input({
  className,
  label,
  type,
  select,
  note,
  options,
  register,
  field,
}) {
  return select ? (
    <div className={className + " grid gap-2"}>
      <label
        className="text-xs sm:text-sm lg:text-base font-bold mb-2"
        htmlFor={label}
      >
        {label}
      </label>
      <select
        id={label}
        type={type}
        className="w-full rounded outline-none focus:outline-none bg-transparent text-xs font-property"
        {...register(field)}
      >
        {options.map((option, key) => {
          return (
            <option value={option} key={key}>
              {option}
            </option>
          )
        })}
      </select>
    </div>
  ) : (
    <div className={className + " grid gap-2"}>
      <label
        htmlFor={label}
        className="text-xs sm:text-sm lg:text-base font-bold"
      >
        {label}{" "}
        {note && (
          <span className="text-xs font-light text-gray-400">
            {" * " + note}
          </span>
        )}
      </label>
      <input
        id={label}
        type={type}
        className="w-full rounded outline-none focus:outline-none bg-transparent text-xs font-property"
        {...register(field)}
      />
    </div>
  )
}
