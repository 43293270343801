import React from "react"
import "swiper/swiper-bundle.min.css"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  Thumbs,
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper"
import {
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
} from "@heroicons/react/solid"
import { GatsbyImage } from "gatsby-plugin-image"

SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade, Thumbs])
export default function Slider({ images, name, className }) {
  return (
    <div>
      <div className="relative z-0">
        <Swiper
          effect="fade"
          navigation={{
            prevEl: ".prev",
            nextEl: ".next",
          }}
          loop={true}
          loopedSlides={5}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          scrollbar={{ draggable: true }}
        >
          {images.map((image, key) => {
            return (
              <SwiperSlide key={key}>
                <GatsbyImage
                  image={image.asset && image.asset.gatsbyImageData}
                  alt={name}
                  // alt="Main Property Image"
                  className={className}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
      <div className=" relative z-10 max-w-5xl mx-auto -mt-24">
        <button className="prev outline-none focus:outline-none">
          <ArrowCircleLeftIcon className="h-10 w-10 text-white fill-current drop-shadow-lg" />
        </button>
        <button className="next outline-none focus:outline-none">
          <ArrowCircleRightIcon className="h-10 w-10 text-white fill-current drop-shadow-lg" />
        </button>
      </div>
    </div>
  )
}
