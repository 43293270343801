import { navigate } from "gatsby-link"
import React, { useState } from "react"
import { useForm } from "react-hook-form"

import qs from "qs"

import { StaticImage } from "gatsby-plugin-image"
import BlockContent from "@sanity/block-content-to-react"
import Map from "../components/map/Map"
import Seo from "../components/seo"

import { HeartIcon } from "@heroicons/react/outline"
import Layout from "../components/layout"
import Slider from "../components/property/Slider"
import Input from "../components/form/Input"
import { numberWithCommas } from "../utils/formatUtil"
export default function PropertiesPage(props) {
  const {
    pageContext: { property },
  } = props

  const schema = {
    "@context": "https://schema.org",
    "@type": "RealEstateListing",
    name: property.name,
    keywords: property.name,
    description: property.highlight,
    contentLocation: property.area,
    thumbnailUrl: property.images[0].asset.gatsbyImageData.images.fallback.src,
    primaryImageOfPage:
      property.images[0].asset.gatsbyImageData.images.fallback.src,
    image: property.images[0].asset.gatsbyImageData.images.fallback.src,
    reviewedBy: "Ibiza High Life",
    url: "https://www.ibizahighlife.com/" + property.slug.current,
  }
  // console.log(property)
  return (
    <div>
      <Layout>
        <Seo
          title={property.name}
          schemaMarkup={schema}
          description={property.highlight}
        />
        <HeadCard property={property} />
        {/* <Booking
          image={property.images[0].asset.gatsbyImageData.images.fallback.src}
          link="/actions/villa/request?name="
          name={property.name}
        /> */}
        <PropertyInfo property={property} />
        <PropertyDescription
          property={property}
          image={property.images[0].asset.gatsbyImageData.images.fallback.src}
          link="/actions/villa/enquire?villaName="
          name={property.name}
        />
      </Layout>
    </div>
  )
}

const HeadCard = ({ property }) => {
  return (
    <div className="w-full mx-auto">
      <div className="p-2 lg:p-8 container mx-auto grid gap-4 sm:gap-0 sm:flex justify-between items-center">
        <div className="grid gap-4">
          <h1>{property.name}</h1>
          <div className="grid lg:flex space-x-0 lg:space-x-24 items-center text-gray-400 text-sm md:text-base">
            <p>{property.area}</p>
            <div className="text-xs md:text-base flex space-x-2 items-center">
              <div className="flex space-x-2 items-center">
                <StaticImage
                  placeholder="tracedSVG"
                  src="../../static/img/bedrooms.svg"
                  width={15}
                  alt="bedroom"
                />
                <span>{property.bedrooms + " Bedrooms"}</span>
              </div>
              <div className="flex space-x-2 items-center">
                <StaticImage
                  placeholder="tracedSVG"
                  src="../../static/img/bathrooms.svg"
                  width={15}
                  alt="bathroom"
                />
                <span>{property.bathrooms + " Bathrooms"}</span>
              </div>
              {/* <span className="flex space-x-2 items-center">
                <StarIcon className="h-5 w-5 text-black" />{" "}
                {property.sleeps + " Sleeps"}
              </span> */}
            </div>
          </div>
        </div>

        {/* <div className="grid gap-4 md:gap-0 md:flex md:space-x-6">
          <button className="text-gray-400 flex space-x-2 items-center">
            <span>Add to wishlist</span>{" "}
            <HeartIcon className="h-10 w-10 text-black" />
          </button>
          <button className="border border-red-600 text-red-600 w-40 h-10 justify-center items-center text-sm font-bold hover:bg-red-500 hover:text-white transition ease-in-out duration-300">
            Book Now
          </button>
        </div> */}
      </div>
    </div>
  )
}

const PropertyInfo = ({ property }) => {
  // console.log(property)
  return (
    <div className="grid grid-cols-12 pl-2 lg:pl-8 container mx-auto">
      <div className="col-span-12 lg:col-span-9">
        {/* <GatsbyImage
          image={
            property.images.length > 0 &&
            property.images[0].asset &&
            property.images[0].asset.gatsbyImageData
          }
          alt={property.name}
          // alt="Main Property Image"
          className="w-full object-cover h-72 md:h-96"
        /> */}
        <Slider
          images={property.images}
          name={property.name}
          className="w-full object-cover h-72 md:h-96"
        />
      </div>
      <div className="col-span-12 lg:col-span-3 h-72 md:h-96 bg-gray-300">
        {property.location && (
          <Map lng={property.location.lng} lat={property.location.lat} />
        )}
      </div>
    </div>
  )
}

const PropertyDescription = ({ property, link, image, name }) => {
  const value = qs.parse(
    typeof window !== "undefined"
      ? window.location.search.substring(1)
      : `name=test`
  )

  const [currentPrice, setCurrentPrice] = useState(value.price || 0)

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      ...value,
    },
  })
  const onSubmit = data => {
    // const searchData = {
    //   ...data,
    //   start: format(data.start, "dd-MM-yyyy"),
    //   end: format(data.end, "dd-MM-yyyy"),
    // }
    const queryString = Object.keys(data)
      .map(key => key + "=" + data[key])
      .join("&")

    navigate(`${link}${name}&${queryString}&image=${image}`)
  }
  return (
    <div className=" w-full mx-auto">
      <div className="p-2 lg:p-8 container mx-auto grid grid-cols-12 pb-8 border-b-2 gap-4">
        <div className="col-span-12">
          <h2 className="text-[16px] lg:text-[24px] font-sans font-normal">
            {property.highlight}
          </h2>
        </div>
        <div className="col-span-12 lg:col-span-9">
          <div className="text-[12px] lg:text-[16px] text-gray-400 leading-[24px] pb-4">
            <BlockContent blocks={property.description} />
          </div>
        </div>
        <div className="col-span-12 lg:col-span-3 pl-0 lg:pl-4">
          {currentPrice && (
            <div className="mb-4 p-4 bg-gray-50 text-center">
              <h1>€{numberWithCommas(currentPrice)}</h1>
              <h3 className="text-gray-400">for your stay</h3>
            </div>
          )}

          <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
            <Input
              label="Check-in Date"
              type="Date"
              className="w-full"
              field="start"
              register={register}
            />
            <Input
              label="Check-out Date"
              type="Date"
              className="w-full"
              field="end"
              register={register}
            />
            <Input
              select
              label="Adults"
              type="Select"
              className="w-full"
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
              field="adults"
              register={register}
            />
            <Input
              select
              label="Children"
              type="Select"
              className="w-full"
              options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
              field="children"
              register={register}
            />
            <button
              type="submit"
              className="w-full border border-black bg-black text-white  h-10 justify-center items-center text-sm font-bold hover:bg-transparent hover:text-black transition ease-in-out duration-300 outline-none focus:outline-none"
            >
              Enquire Now
            </button>
          </form>
        </div>
        <div className="grid grid-cols-12 gap-4 col-span-12">
          <div className="col-span-12 lg:col-span-9">
            <h3 className="text-[16px] font-semibold">Features</h3>
            <ul className="grid grid-cols-3 text-[12px] text-gray-400 gap-4 py-4">
              {property.propertyFeatures.map((feature, key) => {
                return <li key={key}>{feature.name}</li>
              })}
            </ul>
          </div>
          <div className="col-span-12 lg:col-span-3 pl-0 lg:pl-4">
            <h4 className="text-[16px] font-normal pb-4 font-sans">
              Essentials
            </h4>
            <div className="grid  gap-4 text-[12px]">
              <p className="text-gray-400 grid grid-cols-2">
                <span className="font-bold">Check in: </span> <span>10:00</span>
              </p>
              <p className="text-gray-400 grid grid-cols-2">
                <span className="font-bold">Check out: </span>{" "}
                <span>10:00</span>
              </p>
              <p className="text-gray-400 grid grid-cols-2">
                <span className="font-bold">Allow Check in: </span>{" "}
                <span>Flexible checkin days considered.</span>
              </p>
              <p className="text-gray-400 grid grid-cols-2">
                <span className="font-bold">License: </span>{" "}
                <span>VT-300-501</span>
              </p>
              <p className="text-gray-400 grid grid-cols-2">
                <span className="font-bold">Cleaning: </span> <span>Daily</span>
              </p>
            </div>

            {/* <div className="pt-4 lg:pt-24 text-gray-400 grid gap-2 lg:gap-6">
            <div className="flex space-x-4 justify-end items-center">
              <span>Instant Book</span>
              <ShieldCheckIcon className="h-5 w-5 text-black" />
            </div>
            <div className="flex space-x-4 justify-end items-center">
              <span>Best Value</span>
              <CurrencyDollarIcon className="h-5 w-5 text-black" />
            </div>
            <div className="flex space-x-4 justify-end items-center">
              <span>Great Locatons</span>
              <LocationMarkerIcon className="h-5 w-5 text-black" />
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
